@import "../../globals/variables.scss";

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;

  .termsBtn {
    margin-left: 3px;
    cursor: pointer;
    text-decoration: underline;
    transition: color 0.2s;
  }

  p {
    margin: 0;
  }
}
