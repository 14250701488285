@import "variables.scss";

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-family-serif);
  color: var(--heading-color-dark);
  span {
    color: var(--heading-color-secondary) !important;
    display: block;
  }
}

h1 {
  font-weight: var(--font-weight-black);
}
h2,
h3,
h4,
h5,
h6 {
  font-weight: var(--font-weight-bold);
}

h1 {
  font-size: var(--font-size-6xl);
}

h2 {
  font-size: var(--font-size-5xl);
}

h3 {
  font-size: var(--font-size-4xl);
}
h4 {
  font-size: var(--font-size-3xl);
}
h5 {
  font-size: var(--font-size-2xl);
}
h6 {
  font-size: var(--font-size-xl);
}
// These classes allow you to use the style of headings without using headings where it would be semantically incorrect.
.h1 {
  @extend h1;
}
.h2 {
  @extend h1;
}
.h3 {
  @extend h1;
}
.h4 {
  @extend h1;
}
.h5 {
  @extend h1;
}
.h6 {
  @extend h6;
}

sup {
  vertical-align: super;
  font-size: var(--font-size-xs);
}

p :not(.alert),
ul,
li {
  color: var(--body-text-color-dark);
}
ul {
  padding: 0;
}
