@import "../../globals/variables";

.navbar {
  background-color: var(--nav-bg);
  text-transform: uppercase;
  transition: transform 0.3s;
  box-shadow: var(--box-shadow-standard);
  font-size: var(--font-size-xs);

  @include media-breakpoint-up(xl) {
    // Affects navbar only when above the desktop breakpoint
    height: var(--nav-height);

    &.scroll-down {
      transform: translateY(-110%);
    }
  }

  .navbar-nav .nav-link,
  .nav-link {
    white-space: nowrap;
    color: var(--nav-text-color);
    font-weight: var(--font-weight-light);
    letter-spacing: var(--letter-spacing-sm);
    &:hover {
      color: var(--nav-text-hover-color);
    }
  }

  @include media-breakpoint-up(xl) {
    .navbar-nav .nav-link {
      height: var(--nav-height);
      display: inline-flex;
      align-items: center;
    }
  }

  button.navbar-toggler {
    border: none;

    svg {
      color: var(--nav-icon-color);
    }
  }

  @include media-breakpoint-down(xl) {
    // Break to mobile menu is at XL - styles here take effect
    .navbar-collapse {
      margin-top: 1rem;
      // This adds a scrollbar to the inside of the nav when it is bigger than the viewport - useful on mobile
      overflow-y: auto;
      max-height: 100vh;

      /* Scroll 2 */
      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }
      &::-webkit-scrollbar-track {
        background-color: var(--cod-gray);
        border-radius: 6px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba(255, 255, 255, 0.4);
        border-radius: 6px;
      }
    }
  }

  .nav-aside {
    font-size: var(--font-size-xs);

    .nav-link + .nav-link {
      margin-top: 0.5rem;
    }

    @include media-breakpoint-down(xl) {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    a {
      padding: 0;
      font-size: var(--font-size-xxs);

      svg {
        height: 14px;
        margin-right: 6px;
      }
    }
  }
}

.navbar-dark {
  background-color: var(--nav-bg) !important;
}
.dropdown-menu-dark {
  background-color: var(--nav-bg);
}

.dropdown-link {
  padding: 0;

  &.open {
    > svg {
      transform: translateY(20%);
    }
  }

  > svg {
    width: 0.6em;
    margin-left: 0.25rem;
    position: relative;
    top: -3px;
    transition: transform 0.2s;
  }
}

/* Top Navigation Bar */

/* <nav> */
.custom-navbar {
  height: var(--nav-size);
  background-color: var(--nav-bg);
  padding: 0 1rem;
  border-bottom: var(--nav-border);
}

/* <ul> */
.custom-navbar-nav {
  max-width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}

/* <li> */
.custom-nav-item {
  width: calc(var(--nav-size) * 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Icon Button */
.icon-button {
  --button-size: calc(var(--nav-size) * 0.55);
  width: var(--button-size);
  height: var(--button-size);
  background-color: var(--nav-icon-bg);
  border-radius: 50%;
  padding: 5px;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: filter 300ms;

  img {
    &.invert {
      filter: invert(100%);
    }
  }
}

.icon-button:hover {
  filter: brightness(1.2);
}

.icon-button svg {
  fill: var(--nav-text-color);
  width: 20px;
  height: 20px;
}

/* Dropdown Menu */
.custom-dropdown-container {
  position: absolute;
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
  padding: 0;
  overflow: hidden;
  transition: height var(--nav-speed) ease;

  .custom-dropdown {
    display: flex;
    flex-wrap: wrap;
    width: 700px;
    margin: 0 auto;
    background-color: var(--nav-bg);
    border: var(--nav-border);
    border-radius: 0 0 8px 8px;
    padding: 0.75rem 1rem;
    overflow: hidden;
    transition: height var(--nav-speed) ease;
    z-index: 5;
    pointer-events: initial;
    column-count: 2;

    @include media-breakpoint-down(xl) {
      width: auto;
      border-radius: 8px;
      margin-bottom: 0.5rem;
      margin-top: 0.5rem;
    }
    @include media-breakpoint-down(md) {
      column-count: 1;
      padding: 0.5rem;
    }

    .nav-link {
      padding-left: 4px;
      height: 50px;

      @include media-breakpoint-down(xl) {
        height: 45px;
      }
    }
  }

  @include media-breakpoint-down(xl) {
    position: static;
    transform: none;
    top: unset;
    left: unset;
  }
}

.menu {
  width: 100%;
}

.menu-item {
  height: 50px;
  width: calc(50% - 0.5rem);
  margin-right: 0.5rem;
  display: inline-flex;
  align-items: center;
  border-radius: var(--nav-item-border-radius);
  transition: background var(--nav-speed);
  padding: 0.5rem;

  @include media-breakpoint-down(xl) {
    padding: 0;
  }

  @include media-breakpoint-down(md) {
    width: 100%;
  }
}

.menu-item .icon-button {
  margin-right: 0.5rem;
}

.menu-item .icon-button:hover {
  filter: none;
}

.menu-item:hover {
  background-color: var(--nav-menu-item-hover);
}

.icon-right {
  margin-left: auto;
}

h4 svg {
  margin-right: 5px;
  padding-bottom: 5px;
  font-size: 30px;
}
