@import "../../styles/globals/variables.scss";

html,
body {
  padding: 0;
  margin: 0;
  font-family: var(--font-family-sans) !important;
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-regular);
  color: var(--body-text-color-dark);
  background-color: #f5f7fb !important;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.text-right {
  text-align: right !important;
}

.rounded {
  border-radius: var(--border-radius) !important;
}

// Spinner on the buttons styling
.btn {
  .spinner-grow {
    margin-right: 0.3rem;
    vertical-align: 0;
  }
  .spinner-grow-sm {
    width: 0.6rem;
    height: 0.6rem;
  }
}

// Form validation

input.error {
  border-color: $danger;
}

.input-feedback {
  color: $danger;
  font-size: 12px;
  //margin-bottom: 15px;
}

.error-success {
  padding: 0.75rem;

  h5 {
    font-size: 1.375rem;
    margin-top: 0.375rem;
  }
  p {
    margin-bottom: 0;
  }
}
.error-alert {
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.5rem;
  }
}

// Modals
.modal-header {
  background-color: var(--brand-secondary);
  color: var(--brand-secondary-text);
}

.modal-backdrop.show {
  opacity: 0.7 !important;
}

.actions {
  table {
    max-height: 200px;
    margin-bottom: 1rem;

    th {
      padding-bottom: 0.25rem;
    }
    tbody {
      font-size: 15px;
      max-height: 200px;
      overflow-y: auto;
      display: block;
    }
  }
  .form-check-label {
    font-weight: 700;
  }

  form {
    margin-top: 1.75rem;
  }
}
