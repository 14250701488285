@import "../../globals/variables.scss";

section#contact {
  text-align: center;

  .form-control {
    border-radius: 0;
    font-size: var(--font-size-xs);
  }
  .intro {
    margin-left: auto;
    margin-right: auto;
    font-size: var(--font-size-sm);
  }

  .box {
    background: white;
    border-radius: var(--border-radius);

    @include media-breakpoint-up(xs) {
      padding: 1rem 2rem;
      margin: 0;
    }
    @include media-breakpoint-up(md) {
      padding: 3rem 4rem;
      margin: 3rem;
    }
  }

  form {
    text-align: left;
    .form-group,
    .checkbox-group {
      margin: 1rem 0;
    }
    label,
    .checkbox-group-label {
      font-size: medium;
      font-weight: 600;
    }
    input[type="text"],
    input[type="email"],
    input[type="tel"],
    input[type="number"],
    textarea {
      padding: 0.75em 1em;
      border-radius: 0;

      &:not(.is-invalid) {
        border: 1px solid white;
        border-bottom: 2px solid var(--form-input-border-color);
      }
    }

    .was-validated .form-control:invalid,
    .form-control.is-invalid {
      border: 0;
      border-bottom: 2px solid var(--form-input-border-error-color);
    }

    .invalid-feedback {
      font-size: var(--font-size-xxs);
    }
    .checkbox-group {
      margin-bottom: 2rem;

      > div:last-of-type {
        // <div role="group"> that contains the options (labels)
        display: flex;
        flex-wrap: wrap;

        label {
          flex-basis: 33%;
          display: inline-flex;
          align-items: baseline;
          margin-top: 0.5rem;
          padding-right: 0.5rem;

          @include media-breakpoint-down(sm) {
            flex-basis: 50%;
          }
          @include media-breakpoint-only(xs) {
            flex-basis: 100%;
          }

          input {
            margin-right: 0.5rem;
          }
        }
      }
    }
    .radio-group {
      margin-bottom: 0;

      &:not(.is-invalid) {
        padding: 0;

        border: none;
      }

      label {
        display: block;
        margin-top: 0.5rem;

        input {
          margin-right: 0.5rem;
        }
      }
    }
  }

  .btn {
    margin-top: 2rem;
    display: flex;
  }

  .privacy-note {
    font-size: var(--font-size-xxs);
  }
}
