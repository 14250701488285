@import "../../globals/variables.scss";

.btn {
  display: inline-flex;
  align-items: center;
  padding: var(--btn-padding);
  border: none !important;
  border-radius: var(--btn-radius);
  color: var(--button-text-color-light);
  transition: background-color 0.3s, transform 0.3s;

  svg {
    vertical-align: -0.125em;
  }

  &.btn-primary {
    background: var(--button-primary);
    color: var(--button-text-color-light);
    &:hover,
    &:active,
    &:visited,
    &:focus {
      color: var(--button-text-color-light);
      background: var(--button-primary-hover);
    }
    &:disabled {
      background: var(--button-primary-disabled);
    }
  }
  &.btn-secondary {
    background: var(--button-secondary);
    color: var(--button-text-color-light);
    &:hover,
    &:active,
    &:visited,
    &:focus {
      color: var(--button-text-color-light);
      background: var(--button-secondary-hover);
    }
    &:disabled {
      background: var(--button-secondary-disabled);
    }
  }
  &.btn-light {
    color: var(--button-text-color-dark);
    background: var(--button-light);

    &:hover,
    &:active,
    &:visited,
    &:focus {
      color: var(--button-text-color-dark);
      background: var(--button-light-hover);
    }
  }
  &.btn-dark {
    color: var(--button-text-color-light);

    &:hover,
    &:active,
    &:visited,
    &:focus {
      color: var(--button-text-color-light);
      background: var(--button-secondary-hover);
    }
  }

  * {
    margin: auto;
  }

  &.btn-lg {
    padding: var(--btn-lg-padding);
  }

  &.btn-sm {
    padding: var(--btn-sm-padding);
  }

  &.btn-text {
    color: var(--link-text-color-dark);
    text-decoration: underline;
    background: none;
    border-radius: 5px;
    padding: 0 1rem;
    box-shadow: none;
    font-size: var(--font-size-xs);
  }

  &.btn-inline {
    display: inline !important;
    font-size: var(--font-size-xxs);
    margin: 0;
    padding: 0;
    color: var(--link-text-color-dark);
    text-decoration: underline;
    background: none;
    box-shadow: none;
    transition: color 0.2s;

    &:hover {
      color: var(--link-text-color-secondary);
    }
  }
}

.btn + .btn {
  margin-left: 10px;
}
