@function hexToRGB($hex) {
  @return red($hex), green($hex), blue($hex);
}
/* Colors Sass */
$color-black-pearl: #051724;
$color-downriver: #0d324d;
$color-cerulean: #0ea4e6;
$color-cello: #234660;
$color-valencia: #e60000;
$color-red: #d63939;
$color-gold: #b9974d;
$color-green: #95c145;
$color-light: #e4eaf6;
$color-cod-gray: #111111;
$color-shuttle-gray: #586271;
$color-alabaster: #f7f7f7;
$color-black: #000000;
$color-white: #ffffff;
$color-maroon-oak: #621029;
$color-california: #f29f05;

:root {
  --site-bg-color: red;
  --body-text-color-dark: #{$color-cello};
  --body-text-color-light: #{$color-alabaster};
  --body-text-color-primary: #{$color-black-pearl};
  --body-text-color-secondary: #{$color-cerulean};

  --link-text-color-dark: #{$color-cello};
  --link-text-color-light: #{$color-alabaster};
  --link-text-color-primary: #{$color-black-pearl};
  --link-text-color-secondary: #{$color-cerulean};
  --link-text-color-footer: #{$color-gold};

  --color-primary: #{$color-black-pearl};
  --color-primary-accent: #{$color-downriver};
  --color-secondary: #{$color-cerulean};
  --color-secondary-accent: #{darken($color-cerulean, 2.5%)};

  --heading-color-dark: #{$color-black-pearl};
  --heading-color-light: #{$color-alabaster};
  --heading-color-primary: #{$color-black-pearl};
  --heading-color-secondary: #{$color-cerulean};

  --bg-color-light: #{$color-white};
  --bg-color-primary: #{$color-black-pearl};
  --bg-color-primary-accent: #{$color-downriver};
  --bg-color-secondary: #{$color-cerulean};
  --bg-color-secondary-accent: #{darken($color-cerulean, 2.5%)};

  --bg-color-footer: #{$color-cod-gray};

  --button-primary: #{$color-maroon-oak};
  --button-primary-hover: #{darken($color-maroon-oak, 2.5%)};
  --button-primary-disabled: #{$color-maroon-oak};
  --button-secondary: #{$color-black-pearl};
  --button-secondary-hover: #{$color-downriver};
  --button-secondary-disabled: #{$color-downriver};
  --button-light: #{$color-alabaster};
  --button-light-hover: #{darken($color-alabaster, 4%)};
  --button-text-color-dark: #{$color-black-pearl};
  --button-text-color-light: #{$color-alabaster};

  --nav-height: 60px;
  --nav-bg: #{$color-cod-gray};
  --nav-bg-dark: #{$color-cod-gray};
  --nav-bg-dark-hover: #{lighten($color-cod-gray, 4%)};
  --nav-bg-light: #{$color-alabaster};
  --nav-bg-light-hover: #{darken($color-alabaster, 4%)};
  --nav-icon-bg: #{$color-cod-gray};
  --nav-text-color: #{$color-alabaster};
  --nav-text-hover-color: #{$color-california};
  --nav-icon-color: #{$color-alabaster};
  --nav-menu-item-hover: #{$color-cod-gray};
  --nav-menu-dropdown-menu: #{darken($color-alabaster, 4%)};
  --nav-menu-dropdown-font-size: 0.875rem;
  --nav-size: 60px;
  --nav-border: none;
  --nav-item-border-radius: 8px;
  --nav-speed: 500ms;

  --form-input-border-color: #{$color-cerulean};
  --form-input-border-error-color: #{$color-valencia};

  --icon-text-block-margin: 0 0 1.5rem 0;
  --icon-text-block-padding: 1.125rem;
  --icon-text-block-padding-small: 1rem 0;

  --shade-color: #{$color-black};

  --white: #{$color-white};
  --alabaster: #{$color-alabaster};
  --cod-gray: #{$color-cod-gray};

  //--red: #{$color-red};
  --gold: #{$color-gold};
  --green: #{$color-green};
  --light: #{$color-light};
  --blue: #{$color-cerulean};
  --blue-accent: #{darken($color-cerulean, 2.5%)};
  --dark-blue: #{$color-black-pearl};
  --dark-blue-accent: #{$color-downriver};

  --color-primary--rgb: #{hexToRGB($color-cerulean)};
  --color-secondary--rgb: #{darken($color-cerulean, 2.5%)};

  --color-dark-text--rgb: #{hexToRGB($color-cod-gray)};
  --gold--rgb: #{hexToRGB($color-gold)};

  /* Font families */
  --font-family-sans: urw-form, sans-serif !important;
  --font-family-serif: urw-form, sans-serif !important;

  /* Font sizes. */
  --font-size-xxs: 0.75rem; // 12px
  --font-size-xs: 0.875rem; // 14px
  --font-size-sm: 1rem; // 16px
  --font-size-md: 1.125rem; // 18px
  --font-size-lg: 1.313rem; // 21px
  --font-size-xl: 1.5rem; // 24px
  --font-size-2xl: 1.75rem; // 28px
  --font-size-3xl: 2rem; // 32px - H4
  --font-size-4xl: 2.25rem; // 36px - H3
  --font-size-5xl: 2.5rem; // 40px - H2
  --font-size-6xl: 3.125rem; // 50px - H1

  /* Font weights */
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-bold: 700;
  --font-weight-black: 900;

  /* Line heights */
  --line-height-sm: 1.25rem;
  --line-height-md: 1.5rem;
  --line-height-lg: 2rem;

  /* Letter spacings */
  --letter-spacing-sm: 0.05em;
  --letter-spacing-md: 0.1em;

  /* Utilities */
  --width-80: 80%;
  --width-70: 70%;
  --width-60: 60%;
  --width-50: 50%;

  --border-radius: 20px;
  --section-padding-lg: 90px;
  --section-padding-md: 80px;
  --section-padding-sm: 70px;
  --section-padding-xs: 60px;
  --li-padding: 0px 0 0px 30px;
  --btn-padding: 0.5rem 1.5rem;
  --btn-sm-padding: 0.4rem 1rem;
  --btn-lg-padding: 0.6rem 2rem;
  --btn-radius: 50rem !important;

  /* Font shadows */
  --text-shadow-standard: rgba(0, 0, 0, 0.15) 0px 3px 8px;
  --text-shadow-small: rgba(0, 0, 0, 0.5) 1px 1px 1px;
  --box-shadow-standard: rgba(0, 0, 0, 0.15) 0px 3px 8px;
  --box-shadow-fancy: rgba(65, 155, 249, 0.1) 0px 0px 0px 1px, rgba(65, 155, 249, 0) 0px 0px 0px 0px,
    rgba(65, 155, 249, 0.08) 0px 12px 50px 0px, rgba(159, 159, 164, 0.25) 0px 2px 10px 0px;
}
