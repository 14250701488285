.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    margin-left: 0.75rem;
  }
}

.header-split {
  display: flex;
  .push {
    margin-left: auto;
  }
}
