@import "../../globals/variables.scss";

$table-borders: #eee;
$table-controls-borders: #ced4da;

.table-group {
  display: flex;
  align-content: center;
  flex-direction: column;
  background: white;
  padding: 1.5rem 1rem 0rem 1rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16);
  font-size: var(--font-size-xs);

  .table-group-head {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 1.75rem;

    .table-group-head-left {
      display: flex;
      align-items: center;

      .sims-selected {
        position: relative;
        display: flex;
        align-items: center;
        margin: auto 1.5rem;

        .btn-tiny {
          margin-left: 0.375rem;
          margin-bottom: 0.25rem;
        }
      }
    }

    input,
    select {
      height: 40px;
      width: 50px;
      border: 1px solid $table-controls-borders;
      border-radius: 3px;
    }

    .search {
      position: relative;
      display: flex;
      align-items: center;
      height: 40px;

      .dropdown-item {
        font-size: 14px;
      }

      .btn-tiny {
        position: absolute;
        right: 45px;
        top: 50%;
        z-index: 101;
        transform: translateY(-50%);
        font-size: 1.125rem;
        background: transparent;
        border: none;
        padding: 0;
        width: 16px;
        height: 16px;
        line-height: 0;
      }

      input {
        width: 240px;
        padding: 0 0.5rem;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    .details {
      text-align: center;
      padding-left: 1rem;

      input,
      select {
        margin-left: 6px;
      }
    }
  }

  table {
    .btn-text {
      text-decoration: none !important;
    }
  }

  .pagination {
    margin: 2rem auto;

    input {
      padding: 0 4px;
      margin-left: 6px;
      width: 40px;
    }
    .current {
      background: var(--brand-primary);
      color: var(--brand-primary-text);
      cursor: default;
    }
    .spacer {
      text-align: center;
      width: 40px;
    }
  }

  .table-container {
    position: relative;
    min-height: 359px;
    max-width: 100vw;
    max-height: calc(
      100vh - 420px
    ); // roughly the height of the header/toolbar/controls
    overflow-x: auto;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16);

    &.loading {
      overflow-y: hidden;
    }

    table {
      width: 100%;
      border: 1px solid $table-borders;

      &,
      * {
        border-color: $table-borders;
        text-align: left;
      }

      thead {
        position: sticky;
        top: -1px;
        height: 40px;
        background: var(--table-head-bg);
        color: var(--table-head-color);
        z-index: 100;

        &,
        * {
          border: none !important;
        }
      }

      thead th:first-of-type,
      tr td:first-of-type {
        // Centering checkboxes
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
      }
      tr {
        :last-child {
          td {
            border-bottom: 0;
          }
        }
        // Centering checkboxes
        th:first-of-type {
          height: 40px;
        }
        td:first-of-type {
          height: 32px;
        }
        th,
        td {
          &:first-of-type {
            div {
              display: flex;
              align-items: center;
            }
          }
        }
      }
      th,
      td {
        white-space: nowrap;
      }

      border-spacing: 0;

      tr {
        :last-child {
          td {
            border-bottom: 0;
          }
        }
      }

      th,
      td {
        margin: 0;
        padding: 5px 7px;
        border-bottom: 1px solid $table-borders;
        border-right: 1px solid $table-borders;

        :last-child {
          border-right: 0;
        }
      }

      th {
        svg {
          margin-bottom: 4px;
          margin-left: 4px;
        }

        &.right {
          text-align: right;
        }
      }
    }

    .loading {
      display: flex;
      font-size: 1rem;
      align-items: center;
      justify-content: center;
      height: calc(100% - 40px);
      width: 100%;
      background: white;
      color: $dark;
      border: 1px solid $table-borders;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16);
      border-radius: 6px;
      position: absolute;
      top: calc(50% + 20px); // 20px is half the height of the thead
      left: 50%;
      transform: translate(-50%, -50%);
      font-weight: bold;
      text-align: center;
      span {
        margin-left: 0.75rem;
      }
    }

    .no-results {
      display: flex;
      font-size: 1rem;
      align-items: center;
      justify-content: center;
      height: calc(100% - 40px);
      width: 100%;
      background: transparent; // I've made this transparent so that we can see if this ever pops up with results visible, which it obviously shouldn't
      color: $dark;
      border: 1px solid $table-borders;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16);
      border-radius: 6px;
      position: absolute;
      top: calc(50% + 20px); // 20px is half the height of the thead
      left: 50%;
      transform: translate(-50%, -50%);
      font-weight: bold;

      span {
        margin-left: 0.75rem;
      }
    }
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      height: 40px;
      width: 40px;
      border: 1px solid $table-controls-borders;
      background: white;
      font-weight: 700;
      border-radius: 3px;

      &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }

    select {
      height: 40px;
      padding: 0 4px;
      border: 1px solid $table-controls-borders;
      border-radius: 3px;
      margin-left: 6px;
    }
  }
}

// SIM card status conditional colour rendering styles
td {
  span {
    position: relative;
    font-weight: 600;

    &::before {
      position: absolute;
      left: -1rem;
      top: 50%;
      transform: translateY(-50%);
      font-size: 1.375em;
    }
  }
  span:not([class]),
  span.grey {
    font-weight: 400;
  }
  span.Connected {
    color: $success;
  }
  span.Notyetconnected {
    color: $warning;
  }
  span.Availableforhire {
    color: $success;
  }
  span.AwaitingRA {
    color: $warning;
  }
  span.Migrate-inStarted {
    color: inherit;
  }
  span.other {
    color: inherit;
  }

  &.red {
    color: $danger !important;
  }
  span.grey,
  &.grey {
    color: #aaa !important;
  }
}

input.invalid {
  color: red !important;
  border-color: red !important;
}
.table-group-head-left input.form-control {
  position: relative;
  z-index: 100;
}
.table-group-head-left .invalid-feedback {
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}
