@import "../styles/globals/variables.scss";

nav {
  padding-left: 12px;

  .dropdown-toggle {
    display: flex;
    align-items: center;
  }
  .icon {
    font-size: 20px;
    margin-right: 5px;
  }

  .username {
    margin-left: auto;

    span {
      display: flex;
      align-items: center;
      text-transform: lowercase;
    }
  }

  .dropdown-menu {
    padding-top: 0;
    padding-bottom: 0;
  }

  .dropdown-item {
    transition: background-color 0.2s;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    font-size: var(--nav-menu-dropdown-font-size);
    text-transform: capitalize;
  }

  hr.dropdown-divider {
    margin: 0;
  }

  h2 {
    margin-bottom: 0;
  }

  &.light {
    background: var(--nav-bg-light);
    color: var(--nav-bg-dark);

    .dropdown-toggle {
      color: var(--nav-bg-dark);
    }
    .dropdown-menu {
      background-color: var(--nav-bg-light);

      .dropdown-item {
        color: var(--nav-bg-dark);

        &:hover {
          background: var(--nav-bg-light-hover);
        }
      }
    }
  }
  &.dark {
    background: var(--nav-bg-dark);
    color: var(--nav-bg-light);

    .navbar-brand {
      color: var(--nav-bg-light) !important;
      font-weight: 600;
    }

    #logo {
      color: var(--nav-bg-light) !important;
    }

    .dropdown-toggle {
      color: var(--nav-bg-light);
    }
    .dropdown-menu {
      background: var(--nav-bg-dark);

      .dropdown-item {
        color: var(--nav-bg-light);

        &:hover {
          background: var(--nav-bg-dark-hover);
        }
      }
    }
  }
}
