@import "../../globals/variables.scss";

.card-anchor {
  display: inline-flex;
}
.card {
  overflow: hidden;
  margin-bottom: 45px;
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
  box-shadow: var(--box-shadow-fancy);
  background: var(--bg-color-light);
  transition: transform 0.2s;
  border-radius: var(--border-radius);

  > span:first-child {
    // The span containing the Next iamge
    height: 220px !important;
  }

  img {
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
  }

  h3 {
    font-size: var(--font-size-lg);
    font-weight: 700;
    margin: 1.5rem 1rem 0.75rem;
  }
  p {
    font-size: var(--font-size-xs);
    margin: 0 1.25rem 1rem;
  }

  &:hover {
    img {
      transform: scale(1.1) !important;

      filter: brightness(110%) !important;
    }
  }

  .find-out-more {
    margin-top: auto;
    margin-bottom: 2rem;
    color: var(--body-text-color-dark);
    font-weight: 700;
    transition: transform 0.3s;
    font-size: var(--font-size-sm);

    svg {
      height: 14px;
      margin-left: 4px;
      margin-bottom: 1px;
      transition: transform 0.9s ease;
    }
  }
}

.id-info {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;

  span {
    font-size: 14px;
    span {
      font-weight: 600;
    }
  }
}
