.toolbar {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: center;
    height: 90px;
    padding: 12px 24px;
    background: var(--toolbar-bg);
    color: var(--toolbar-text);
  
    h2 {
      margin-right: 2rem;
    }
  
    .toolbarButton {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      height: 64px;
      min-width: 64px;
      margin: auto 5px;
      padding: 0 8px;
      border: solid 1px ;
      border-radius: 4px;
      background: rgba(255, 255, 255, 0);
      color: var(--toolbar-text);
      font-size: 14px;
      font-weight: 400;
      transition: background-color 0.2s;
      pointer-events: all;
  
      &[disabled="disabled"],
      &:disabled {
        
  
        *:not(.custom-tooltiptext) {
          opacity: 0.5 !important;
        }
  
        background: transparent;
        cursor: not-allowed;
        &:hover {
          background: transparent;
        }
      }
  
      &.small {
        flex-direction: row;
        justify-content: flex-start;
        height: 30px;
        min-width: 95px;
        margin: 0 5px;
  
        & + .small {
          margin-top: 4px;
        }
  
        i {
          height: 18px;
          width: 18px;
          margin-bottom: 0;
          margin-right: 6px;
        }
      }
  
      &:hover {
        background: rgba(255, 255, 255, 0.1);
      }
  
      i {
        height: 24px;
        width: 24px;
        margin-bottom: 6px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
}